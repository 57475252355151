import React from "react";
import bg from "../utils/bluetexd.jpg";

export const Footer = () => {
  return (
    <div className="relative h-[60px] w-screen">
      <img
        src={bg}
        alt=""
        className="absolute h-[60px] w-screen object-cover  brightness-[40%]"
      />
      <div className="absolute h-[60px] w-screen z-[30px] bg-[#00800090]"></div>

      <div className="relative flex h-[60px] justify-center items-center text-[white] text-lg md:text-2xl">
        &copy; Cinetico Media
      </div>
    </div>
  );
};
