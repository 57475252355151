import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import "react-typist/dist/Typist.css";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import React, { Suspense, useEffect, useState } from "react";
import "./css/main.css";
import AOS from "aos";
import Main from "./components/main";
import Services from "../src/components/services";
import Adiin, { Admin } from "../src/components/admin";
import Login from "./components/login";

function App() {
  const [loading, setLoading] = useState("");

  useEffect(() => {
    AOS.init();
  }, []);

  return loading ? (
    <div className="h-screen flex justify-center items-center">
      <div class="spinner-border text-green-400" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  ) : (
    <div>
      <Routes>
        <Route element={<Main></Main>} path="/"></Route>
        <Route element={<Services></Services>} path="/services/:id"></Route>
        <Route element={<Login></Login>} path="/login"></Route>
        <Route element={<Admin></Admin>} path="/admin"></Route>
      </Routes>
    </div>
  );
}

export default App;
