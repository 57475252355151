import React, { useEffect } from "react";
import { Navbar } from "./navbar";
import hero from "../utils/clientbg1.jpeg";
import hero2 from "../utils/mybg2.jpg";
import hero3 from "../utils/mybg.jpg";
import { TypeAnimation } from "react-type-animation";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import about from "../utils/myhorse.jpg";
import CountUp from "react-countup";
import { useState } from "react";
import ScrollTrigger from "react-scroll-trigger";
import icon1 from "../utils/icon1.png";
import kenneth from "../utils/kenneth.png";
import kenneth2 from "../utils/kenneth2.jpg";
import esther from "../utils/esther.jpeg";
import favour from "../utils/favour.jpeg";
import icon2 from "../utils/icon2.png";
import icon3 from "../utils/icon3.png";
import strategy from "../utils/strategy.jpg";
import promotion from "../utils/apppro.jpg";
import brand from "../utils/brand2.jpg";
import digital from "../utils/advertising.jpg";
import publicr from "../utils/public-relations.jpg";
import media from "../utils/media relations.jpeg";
import Carousel from "react-bootstrap/Carousel";

import content from "../utils/content.jpeg";

import netflix from "../utils/netflix.png";
import applatch from "../utils/applatch.jpg";
import reaprite from "../utils/reaprite.png";
import rentspace from "../utils/rentspace.png";
import techmarket from "../utils/techmarket.png";
import froshtech from "../utils/froshtech.png";
import a1tutor from "../utils/a1tutor.png";

import vanir from "../utils/vanir.png";
import arbritos from "../utils/arbritos.png";
import zumera from "../utils/zumera.png";
import brimestone from "../utils/brimestone.png";
import modesty from "../utils/modesty.png";
import fab from "../utils/fab.png";
import property from "../utils/property2.jpg";
import bigbrother from "../utils/big brother.png";
import { Autoplay, Pagination } from "swiper";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import contact from "../utils/contact.jpg";
import { Footer } from "./footer";
import Axios from "axios";
import Toast from "react-bootstrap/Toast";

import paul from "../utils/IMG-20230518-WA0003.jpg";
import hassanah from "../utils/IMG-20230518-WA0004.jpg";
import toyin from "../utils/IMG-20230518-WA0005.jpg";
import adeniyi from "../utils/IMG-20230518-WA0006.jpg";
import femi from "../utils/IMG-20230518-WA0007.jpg";
import samson from "../utils/IMG-20230518-WA0008.jpg";
import habib from "../utils/IMG-20230518-WA0009.jpg";
import realty from "../utils/IMG-20230518-WA0010.jpg";
import nova from "../utils/WhatsApp Image 2023-05-18 at 2.56.19 PM.jpeg";

export const Main = () => {
  const navigate = useNavigate();
  const [counterOn, setCounterOn] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [fullname, setFullname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [loading, setLoading] = useState("");
  const [showA, setShowA] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setErrorMessage("");
    }, 6000);
  }, [errorMessage]);
  const services = [
    {
      id: 1,
      title: "Strategic Planning",
      info: "We work closely with clients to launch new products and services. We do this by developing comprehensive marketing, advertising, and PR strategies that align with their marketing goals and objectives. Our solutions are curated to meet the needs and objectives of our clients. We will continually monitor and analyze your campaign performance, making real time adjustments to ensure maximum return on investment. With us, the promise is increased ad visibility, higher engagement and conversion rates and sprangling your brand across your industry.",
      sub: "We work closely with clients to launch new products and services. ",
      image: strategy,
    },
    {
      image: promotion,
      id: 2,
      title: "App Promotion",
      info: "We ignite your mobile application by exponentially increasing downloads and user acquisition up to 100x (10 times) more. We attain this on our various partner networksTrue to our core value of transparency and accountability, we provide periodic reports that detail progress and results on campaigns - clearly depicting intended results.Cinetico exists to help you achieve your set goals.",
      sub: "We ignite your mobile application by exponentially increasing downloads and user acquisition up to 100x (10 times) more ",
    },
    {
      image: brand,
      id: 3,
      title: "Brand Activation",
      info: "With a proven track record of successfully delivering to clients across a range of industries, we are passionate about helping businesses like yours bring their brand to life and create meaningful connections with their customers. Whether you're launching a new product or looking to reinvigorate your brand, our team delivers value every step of the way. From concept development to execution, we ensure that every detail is carefully considered to maximize impact and engagement.Our unique brand activation approach goes beyond promoting your product and services. We utilize a people-first approach to elicit clientele relationships that invoke brand loyalty and create a sense of community.Whether it's through experiential marketing, social media campaigns, influencer collaborations, or other innovative techniques, we you create a strong and lasting impression just for you",
      sub: " We are passionate about helping businesses like yours bring their brand to life and create meaningful connections with their customers.  ",
    },
    {
      image: digital,
      id: 4,
      title: "Digital Advertising",
      info: "We help our clients reach their target audience through uniquely curated digital advertising campaigns. Our team of experts collaborate with you to develop and execute a digital advertising plan that maximizes your return on investment.Our approach is pragmatic and programmatic. We give you hands-on experience using advanced technology. We do this by automatic digital ads across multiple platforms. For you, this means optimizing your budget while reaching the target audience at the right time, place and on the right device",
      sub: " We help our clients reach their target audience through uniquely curated digital advertising campaigns.   ",
    },
    {
      image: publicr,
      id: 5,
      title: "Public Relations",
      info: "We write your story in a compelling way to create and maintain a positive brand image with your niche audience. Your brand reputation and positive media coverage is why the Team at Cinetico rises at dawn.",
      sub: " We write your story in a compelling way to create and maintain a positive brand image with your niche audience.   ",
    },
    {
      image: media,
      id: 6,
      title: "Media Relations",
      info: "As you come on board, you ride on the horseback of our profound relationships with top-tier media outlets. You enjoyed increased visibility, credibility and world wide coverage.",
      sub: " You enjoyed increased visibility, credibility and world wide coverage",
    },
    {
      image: content,
      id: 7,
      title: "Content Marketing",
      info: "We position your brand as an industry thought leader using high quality and original content. Our Team of Expert Writers, Designers and Video Producers curate content that resonates with your audience, adds to your bottom line and drives conversions.",
      sub: " We position your brand as an industry thought leader using high quality and original content.",
    },
  ];
  const meet = [
    {
      id: 1,
      name: "Kenneth Etiaka",
      role: "Lead Consultant ",
      info: "Kenneth Etiaka is a dynamic media development enthusiast with wide range of cognitive experience, leading successful teams in the most reputable business media organizations in Nigeria.   With specialized skills & strengths in corporate advertising, and strategic brand partnership, Kenneth leverages on his functional knowledge of clients advisory, media buying  and Lead generation.   Kenneth is proficient & versatile in the blends of conventional and new age media promotions",

      image: kenneth,
    },
    {
      id: 2,
      name: "Andrew Esther Oghenetega",
      role: "Broadcast Account Manager",
      info: "Andrew Esther Oghenetega is a media enthusiast with proficient passion for; Media planning, Cost control techniques, Managing clients campaign portfolios and Broadcast management.   As a keenly Social Media participant with in-depth knowledge of content creation and platform algorithm, she optimizes budgets to guarantee ripple returns on investments.  Tega as fondly called, is quite versed and remains a reputable veteran in modern day media engagements with enviable talents as an On Air Presenter ",

      image: esther,
    },
    {
      id: 3,
      name: "Favour Uzondu Oriaku",
      role: "Brand Strategist",
      info: "Favour Uzondu Oriaku holds a Bachelor of Science degree in Sociology from Imo State University, Owerri, and a Masters of Business Administration from Bayero University, Kano, with specialization in Human Resources.   He is a trained & accomplished brand strategist, social media publicist, creative graphic designer and an exceptional video editor.   He is keenly interested in Content creation with unique features, Social media management, Brand design advisory and management, amongst others.   He enthusiastically brings his wealth of experience onboard the team. ",

      image: favour,
    },
  ];
  const testimonials = [
    {
      id: 1,
      info: "I recently had the pleasure of working with the Cinetico team on an App optimization campaign for Applatch and I'm thrilled to share about the project's success.We were aiming for a higher user acquisition milestone, and that's when we decided to partner with the Cinetico team, with the execution led by Kenneth in an App user acquisition campaign. It was a game-changer! Thanks to the expertise and efforts of Kenneth and the optimization team, Our app's visibility improved drastically, resulting in a significant increase in downloads (over 14,000) and user engagement.The results of the App optimization campaign exceeded our expectations. Within a few weeks, our app started climbing the ranks, and our organic downloads increased significantly. Our app's visibility improved dramatically, leading to a substantial boost in user acquisition and retention. Not only did we achieve our initial milestone goals, but we also surpassed them, leading to increased business growth.I am thrilled with the success of the app optimization campaign and highly recommend it to any App owner looking to improve their app's visibility and performance.",
      name: "Samson Opeleye",
      gender: "male",
      role: "CEO Applatch",
      image: samson,
    },
    {
      id: 2,
      info: "As I interacted with the Team at Cinetico, I found their knowledge of the media impressive. They sure gave insights on the direction to take regarding our business.",
      name: "Femi Ogunibe",
      gender: "male",
      role: "Founder/Tech market",
      image: femi,
    },

    {
      id: 3,
      info: "‘The Team Lead, Kenneth reached out to me from my social media footprints and asked if my organization had any PR action ongoing. We truly didn’t and have been tinkering with the idea of working with a Media Agency for months. The Cinetico Team was patient asI struggled through the decision and some personal challenges. The Team Lead is an attentive listener and got a grasp of what I needed to set my business in the appropriate direction. Now, work is in progress to actualize my dreams for my company. So far, so good, Cinetico Media is the way to go.",
      name: "Toyin Lolu-Ogunmade",
      gender: "female",
      role: "Founder & CEO Precious Conceptions",
      image: toyin,
    },

    {
      id: 4,
      info: "I have had the pleasure of working with many advertising/marketing professionals. Still, Kenneth stands out mainly because he is focused on results and brings a lot of positive energy to every campaign. He has the connections, expertise, and links to project any business to reach its target audience. If you're serious about your business visibility, Kenneth is your guy! I highly recommend him.",
      name: "Adeniyi Moses Adetola",
      gender: "male",
      role: "Co-Founder/CEO Selloff NG",
      image: adeniyi,
    },

    {
      id: 5,
      info: "An Advert Feature on BusinessDay was what Cinetico Media suggested during consultation. It was a game changer exposè for us and it linked us with prospects that are now clients - returning customers, I must say.",
      name: "Atomikun Adebayo Oyetoro",
      gender: "male",
      role: "CEO. Fab Realty and Tourism",
      image: realty,
    },

    {
      id: 6,
      info: "Just wanted to share a quick note and let you know that you guys really did a good job on our publication. I’m glad we decided to work with you. I’ll give my recommendations to any business looking to put their name/brand out there. We will be back for more publicity deals.’",
      name: "Sandra Ngwumezie",
      gender: "female",
      role: "Head of Business Development eAjor",
      image: icon3,
    },

    {
      id: 7,
      info: "The team at Cinetico are passionate about their job. I like how they are particular about bringing value and satisfaction to me as a client. Great service!",
      name: "Olanipekun Paul ",
      gender: "male",
      role: "Founder/Myadmissionlink Education Consult",
      image: paul,
    },
    {
      id: 8,
      info: "The PR team that attended to me did a fine job of unraveling some gaps that needed to be bridged in our public relation efforts. Our organization’s needs were attended to and I was left satisfied.",
      name: "Habib Akande ",
      gender: "male",
      role: "MD/Brimestone NG",
      image: habib,
    },
    {
      id: 9,
      info: "Mr Kenneth understands the world of brand communication. We got the best strategy for placing our ads and the entire process gave the results we wanted. Also, the Team was super responsive - I recommend Cinetico. ",
      name: "DMCS",
      gender: "male",
      role: "Vanir Capital ",
      image: icon2,
    },

    {
      id: 11,
      info: "I am glad for the feature. I made huge sales the first week of publication. Thanks for the visibility",
      name: "Hassanah Salami      ",
      gender: "female",
      role: "Modesty Signature",
      image: hassanah,
    },
  ];

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <div>
      <Navbar></Navbar>
      {loading && (
        <div className="fixed top-0 w-[100vw]   h-[100vh] z-50 overflow-x-hidden bg-[#00000064] bg-cover flex justify-center items-center">
          <svg
            class="pl overflow-x-hidden"
            viewBox="0 0 200 200"
            width="200"
            height="200"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                <stop offset="0%" stop-color="hsl(313,90%,55%)" />
                <stop offset="100%" stop-color="hsl(223,90%,55%)" />
              </linearGradient>
              <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stop-color="hsl(313,90%,55%)" />
                <stop offset="100%" stop-color="hsl(223,90%,55%)" />
              </linearGradient>
            </defs>
            <circle
              class="pl__ring"
              cx="100"
              cy="100"
              r="82"
              fill="none"
              stroke="url(#pl-grad1)"
              stroke-width="36"
              stroke-dasharray="0 257 1 257"
              stroke-dashoffset="0.01"
              stroke-linecap="round"
              transform="rotate(-90,100,100)"
            />
            <line
              class="pl__ball"
              stroke="url(#pl-grad2)"
              x1="100"
              y1="18"
              x2="100.01"
              y2="182"
              stroke-width="36"
              stroke-dasharray="1 165"
              stroke-linecap="round"
            />
          </svg>
        </div>
      )}

      <Carousel slide={false} fade pause={false}>
        <Carousel.Item>
          <div className="hero relative ">
            <img
              src={hero}
              className="absolute h-[70vh] md:h-[90vh] object-cover w-screen brightness-[30%]  overflow-x-hidden zoominheader  "
            ></img>
            <div className="relative pl-5 h-[70vh]   md:h-[90vh] w-screen flex flex-col justify-center  text-white overflow-x-hidden">
              <div className="w-[300px] sm:w-[550px] md:w-[600px]  mt-[-50px]  bg-[#00000088] rounded-xl px-3 py-4  ">
                <h3 className="text-[#25a025] animate-charcter text-xl md:text-3xl lg:text-4xl  font-bold">
                  Welcome to Cinetico Media
                </h3>
                <p className=" mt-2 lg:mt-4  text-white leading-[30px] text-2xl md:leading-[40px]   md:text-3xl ">
                  We help in Building a Loyal Audience Base for Every Brand
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="hero relative ">
            <img
              src={hero2}
              className="absolute h-[70vh] md:h-[90vh] object-cover w-screen brightness-[30%]  overflow-x-hidden zoominheader"
            ></img>
            <div className="relative pl-5 h-[70vh]   md:h-[90vh] w-screen flex flex-col justify-center  text-white overflow-x-hidden">
              <div className="w-[300px] sm:w-[550px] md:w-[600px]  mt-[-50px]  bg-[#00000088] rounded-xl px-3 py-4  ">
                <p className=" mt-2 lg:mt-4  text-white leading-[30px] text-2xl md:leading-[40px]   md:text-3xl ">
                  We make your brand an industry authority through exceptional,
                  original content.
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="hero relative ">
            <img
              src={hero3}
              className="absolute h-[70vh] md:h-[90vh] object-cover w-screen brightness-[30%]  overflow-x-hidden zoominheader"
            ></img>
            <div className="relative pl-5 h-[70vh]   md:h-[90vh] w-screen flex flex-col justify-center  text-white overflow-x-hidden">
              {/* w-[600px] */}
              <div className=" md  w-[300px] sm:w-[570px] md:w-[600px]  mt-[-50px] bg-[#000000b5] rounded-xl px-3 py-4  ">
                <p className=" mt-2 lg:mt-4  text-white leading-[30px] text-2xl md:leading-[40px]    md:text-3xl  ">
                  We connect businesses like yours with diverse customers.
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>

      <div className="bg-slate-100  py-[50px]">
        <div
          data-aos="fade-right"
          className="text-center uppercase text-xl md:text-3xl font-bold"
        >
          Our Clients
        </div>
        <div className=" flex justify-center ">
          <div className="h-[3px] w-[70px] mt-[15px] bg-green-700"></div>
        </div>
        <div
          data-aos="fade-right"
          className="text-center my-4 px-3 text-lg md:text-xl"
        >
          We have provided UNfair Marketing, Advertising and Public Relations
          (PR) advantage to these companies
        </div>
        <div className="mt-[40px] mb-[40px]">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={10}
            autoplay={{
              delay: 700,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="flex justify-center items-center  py-3">
                <img src={netflix} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="flex justify-center   py-3">
                <img src={applatch} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              {" "}
              <div className="flex justify-center items-center  py-3">
                <img src={reaprite} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="flex justify-center items-center    py-3">
                <img src={rentspace} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="flex justify-center items-center    py-3">
                <img src={techmarket} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div className="flex items-center justify-center mb-[-160px]">
                <div className="text-3xl italic text-red-600">Frosh Tech</div>
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              {" "}
              <div className="flex justify-center items-center    py-3">
                <img src={bigbrother} className="h-[100px]" alt="" />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              {" "}
              <div className="flex justify-center  bg-black py-3">
                <img src={a1tutor} className="h-[80px] w-[200px] " alt="" />
              </div>
            </SwiperSlide> */}
            <SwiperSlide>
              {" "}
              <div className="flex justify-center items-center    py-3">
                <img src={nova} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="flex justify-center items-center    py-3">
                <img src={vanir} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="flex justify-center items-center    py-3">
                <img src={arbritos} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex justify-center items-center    py-3">
                <img src={modesty} className="h-[100px] mt-[-20px]" alt="" />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="flex justify-center items-center    py-3">
                <img src={property} className="h-[100px] mt-[-20px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center    py-3">
                <img src={a1tutor} className="h-[100px] mt-[-20px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center    py-3">
                <img src={zumera} className="h-[130px] mt-[-40px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center    py-3">
                <img
                  src={brimestone}
                  className="h-[190px] md:h-[130px] mt-[-30px]"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center    py-3">
                <img
                  src={fab}
                  className=" h-[140px] md:h-[100px] mt-[-20px]"
                  alt=""
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="flex justify-center items-center    py-3">
                <img src={froshtech} className="h-[110px] mt-[-20px]" alt="" />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              {" "}
              <div className="flex justify-center  bg-black py-3">
                <img src={fab} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="flex justify-center  bg-black py-3">
                <img src={zumera} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              {" "}
              <div className="flex justify-center  bg-black py-3">
                <img src={brimestone} className="h-[80px]" alt="" />
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
        {/* Continue */}
      </div>
      <div className="flex flex-col md:flex-row justify-around items-center p-2 md:p-5  my-[20px] md:my-[70px] ">
        <img
          src={about}
          // data-aos="fade-right"
          className=" md:w-[400px] lg:w-[580px] object-cover  md:mr-8 px-2 pt-5 pb-5 md:p-0"
          alt=""
        />

        <div id="about" data-aos="fade-left" className=" px-3 md:px-0">
          <div className="text-xl md:text-2xl font-bold mb-3 uppercase   ">
            A<span className="text-green-700">b</span>out{" "}
            <span className="text-green-700">U</span>s
          </div>
          <div className="text-2xl lg:text-4xl mb-4 font-bold  text-green-700">
            We are the Horsemen of Corporations!
          </div>
          <div className="text-lg ">
            Welcome to Cinetico Media, a full-fledged Marketing, Advertising,
            and Public Relations (PR) Agency that delivers top-notch solutions
            to businesses of all sizes. We serve Startups, Established
            Businesses and Organizations needing to make a buzz about the unique
            products and services they sell and offer. Our team of experts
            specializes in crafting unforgettable strategies that drive your
            growth and generate real results. At Cinetico, we believe in the
            power of integrated Marketing, Advertising, and PR solutions.
          </div>
        </div>
      </div>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      >
        <div className="mt-[50px] ">
          <div className="relative">
            <img
              src={hero2}
              alt=""
              className="absolute h-[200px] w-screen object-cover brightness-[10%]"
            />
            <div className="relative  h-[200px]">
              {counterOn && (
                <div className="grid grid-cols-3  h-[200px] items-center justify-around ">
                  <div className="flex flex-col justify-center items-center pr-[0.75rem]">
                    <h4 className="font-bold text-white text-2xl md:text-3xl lg:text-5xl">
                      <CountUp
                        start={0}
                        end={100}
                        delay={0}
                        duration={2}
                      ></CountUp>
                      +
                    </h4>
                    <span className="text-white text-xs md:text-lg lg:text-2xl text-center">
                      Projects Done
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center pr-[0.75rem]">
                    <h4 className="font-bold text-white text-2xl md:text-3xl lg:text-5xl">
                      <CountUp
                        start={0}
                        end={96}
                        delay={0}
                        duration={2}
                      ></CountUp>
                      +
                    </h4>
                    <span className="text-white text-xs md:text-lg lg:text-2xl text-center">
                      Satisfied customers
                    </span>
                  </div>
                  <div className="flex flex-col justify-center items-center pr-[0.75rem]">
                    <h4 className="font-bold text-white text-2xl   md:text-3xl lg:text-5xl">
                      <CountUp
                        start={0}
                        end={10}
                        delay={0}
                        duration={2}
                      ></CountUp>
                      +
                    </h4>
                    <span className="text-white text-xs md:text-lg lg:text-2xl text-center">
                      Years of Experience
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </ScrollTrigger>

      <div id="services" className=" bg-slate-50 pt-[90px] md:pt-[140px]">
        <h5
          data-aos="fade-right"
          className="text-center mb-2 uppercase text-xl md:text-3xl text-green-700 font-bold "
        >
          OUR SERVICES
        </h5>
        <div className=" flex justify-center ">
          <div className="h-[3px] w-[70px] mt-[-1px] bg-green-700"></div>
        </div>
        {/* absolute top0 */}
        <div className="">
          <div className="mt-[10px]   grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-3 ">
            {services.map((item) => {
              return (
                <div
                  data-aos="fade-down"
                  className="shadow-xl py-3  text-center mt-5 md:mr-3"
                >
                  <img
                    src={item.image}
                    alt=""
                    className="mb-4 h-[200px] object-cover object-center w-full "
                  />
                  <div className="px-3 flex flex-col  items-center justify-center">
                    <h3 className="font-bold">{item.title}</h3>
                    <p>{item.sub}</p>
                    <div
                      onClick={() => navigate(`/services/${item.id}`)}
                      className="mt-3 flex items-center hover:cursor-pointer  hover:scale-105 transition-all "
                    >
                      <h4 className="text-lg hover:text-green-700 text-green-700 mr-2 mt-[2px]">
                        Read More
                      </h4>
                      <i className="bi bi-arrow-down-right-circle-fill text-lg hover:text-green-700 text-green-700 mt-[-2px]"></i>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div id="testimonials" className="mt-[60px] md:mt-[110px]">
        <h5 className="text-center uppercase text-xl md:text-3xl text-green-700 font-bold ">
          Testimonials
        </h5>
        <div className=" flex justify-center ">
          <div className="h-[3px] w-[70px] mt-[-4px] bg-green-700 mb-[30px]"></div>
        </div>
        <div className="my-8 px-3  ">
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            loop={true}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              900: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            modules={[Autoplay]}
            className="mySwiper cursor-grab gap-5 pb-5"
          >
            {testimonials.map((item) => {
              return (
                <SwiperSlide>
                  <div className="p-6 flex shadow-inner flex-col items-center justify-center  bg-slate-100 h-[450px] rounded-xl">
                    <div>
                      <div className="flex  items-center self-start">
                        <img
                          src={item.image}
                          alt=""
                          className="h-[75px] w-[75px] rounded-[50%] object-cover"
                        />
                        <div className="ml-6 flex flex-col">
                          <div className="font-semibold text-xl text-slate-700 text-start">
                            {item.name}
                          </div>
                          <div
                            style={{
                              alignSelf: "flex-start",
                              textAlign: "start",
                            }}
                            className="items-start text-sm text-green-700"
                          >
                            {item.role}
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 text-slate-800 text-lg text-start pl-5">
                        {item.info.split(" ").slice(0, 30).join(" ")}{" "}
                        {item.info.split(" ").length > 30 && "..."}
                      </div>

                      <div className="flex justify-start ">
                        {item.info.split(" ").length > 30 && (
                          <button
                            onClick={() => {
                              setModalContent(
                                testimonials.find(
                                  (eachtest) => item.id === eachtest.id
                                )
                              );
                              setShow(true);
                            }}
                            className="py-1 mt-2 ml-5 px-2 bg-green-700 text-sm text-white rounded-lg hover:bg-green-700"
                          >
                            Read more
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        {/* <div className="py-[20px] md:pt-[50px] bg-slate-50">
          <h5
            data-aos="fade-right"
            className="text-center mt-3 uppercase text-xl md:text-3xl text-green-700 font-bold "
          >
            Meet the team
          </h5>
          <div className=" flex justify-center ">
            <div className="h-[3px] w-[70px] mt-[-1px] bg-green-700 mb-[30px]"></div>
          </div>
          <div className="   grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 px-3 mb-5 ">
            {meet.map((item) => {
              return (
                <div className="shadow-2xl border-2 rounded-sm border-slate-200  flex flex-col py-5 items-center justify-center text-center px-3 mt-3 md:mr-3">
                  <img
                    src={item.image}
                    alt=""
                    className="mb-3 h-[150px] rounded-2xl shadow-2xl "
                  />
                  <h3 className="font-bold text-center text-xl text-green-700 mt-4">
                    {item.name}
                  </h3>
                  <p className="mt-2">{item.role}</p>
                  <div className="mt-3 flex items-center hover:cursor-pointer  hover:scale-105 transition-all mydiv">
                    <button
                      onClick={() => {
                        setModalContent(item);
                        setShow(true);
                      }}
                      className="button-29"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        <div className="py-[90px] mb-5 flex flex-col-reverse lg:flex-row pb-5 justify-center bg-slate-50 ">
          <div className="relative bg-green-700 py-[20px] lg:ml-[50px]  lg:w-[37vw] h-[300px] md:h-[400px] lg:h-[500px] pl-8">
            <img
              src={contact}
              alt=""
              className="absolute top-0 left-0 opacity-25 object-cover brightness-[60%] w-[100vw] lg:w-[40vw] h-[300px] md:h-[400px] lg:h-[500px]"
            />
            <div className="relative">
              <h3 className="text-2xl  text-white pt-5 pb-3">
                Let's get in Touch
              </h3>

              <div className="  flex items-center mt-4">
                <div className=" h-[50px] w-[50px]  border-2 border-[#ffffff67] rounded-[50%] flex justify-center items-center">
                  <i className="bi bi-phone-fill text-2xl text-white "></i>
                </div>
                <h4 className="text-white text-xl ml-4">
                  Phone: +2348036964338
                </h4>
              </div>
              <div className="  flex items-center mt-4">
                <div className=" h-[50px] w-[50px]  border-2 border-[#ffffff67] rounded-[50%] flex justify-center items-center">
                  <i className="bi bi-envelope-at-fill text-2xl text-white "></i>
                </div>
                <h4 className="text-white text-xl ml-4">
                  Email: +Info@cineticomedia.com
                </h4>
              </div>
            </div>
          </div>
          <div
            id="contact"
            className="right max-w-screen overflow-hidden lg:h-[500px] lg:w-[65vw] lg:pl-[140px] mycont pb-[70px] lg:mb-[0]  px-6  bg-slate-50"
          >
            <div
              className="pt-5 text-3xl font-bold mb-[40px] "
              data-aos="fade-left"
            >
              <span className="text-green-700">Send</span> us a
              <span className="text-green-700"> message</span>
            </div>
            <form
              action=""
              onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                try {
                  await Axios.post("https://cineticoapi.onrender.com/message", {
                    name: fullname,
                    email: email,
                    message: message,
                  });

                  setErrorMessage("Submitted successfully");
                  setFullname("");
                  setmessage("");
                  setemail("");
                  setLoading(false);
                } catch (error) {
                  setLoading(false);
                  console.log(error);
                }
              }}
            >
              <div className="flex flex-wrap gap-y-4 ">
                <div className="flex flex-col mr-10 mt-2">
                  <div className="uppercase text-sm mb-2 text-green-700">
                    FULL NAME
                  </div>
                  <input
                    type="text"
                    placeholder="Name"
                    required
                    value={fullname}
                    onChange={(e) => {
                      setFullname(e.target.value);
                    }}
                    className="placeholder:text-slate-400 focus:border focus:border-green-500 pr-6 bg-transparent focus:pl-3  border-b-2 py-2  border-slate-400"
                  />
                </div>
                <div className="flex flex-col mt-2 ">
                  <div className="uppercase text-sm mb-2 text-green-700">
                    Email Address
                  </div>
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    value={email}
                    onChange={(e) => {
                      setemail(e.target.value);
                    }}
                    className="placeholder:text-slate-400 bg-transparent pr-6 focus:pl-3 border-b-2 py-2  border-slate-400"
                  />
                </div>
              </div>
              <div className="pt-10">
                <div className="flex flex-col mr-10 mt-2">
                  <div className="uppercase text-sm mb-2 text-green-700">
                    Message
                  </div>
                  <input
                    type="text"
                    required
                    value={message}
                    onChange={(e) => {
                      setmessage(e.target.value);
                    }}
                    placeholder="Enter your message"
                    className="placeholder:text-slate-400 bg-transparent pr-6 focus:pl-3 border-b-2 pt-2 pb-[120px] myinput border-slate-400 md:w-[470px]"
                  />
                </div>
              </div>

              <div className="mydiv">
                <button type="submit" className="button-29 mt-4 md:mt-5">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <Footer></Footer>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body className="font-semibold">{modalContent.info}</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {errorMessage !== "" && (
        <div className="fixed z-50 top-[30px] right-0 rounded-2xl shadow-2xl text-white fade-in-bck1  ">
          <Toast
            show={showA}
            style={{
              paddingTop: "10px",
              paddingBottom: "6px",
              border: "2px solid red",

              marginLeft: "-10px",

              paddingLeft: "10px",
              paddingRight: "10px",
              backgroundColor: "#ff00001c",
              color: "red",
              fontWeight: "800",
            }}
          >
            <Toast.Body className="rounded-t-lg uppercase pt-[60px]">
              {errorMessage}
            </Toast.Body>
          </Toast>
        </div>
      )}
    </div>
  );
};

export default Main;

// Change countup on small screen
