import React, { useEffect, useState } from "react";
import { Navbar } from "./navbar";
import { NavbarForServices } from "./navbarforservices";
import strategy from "../utils/strategy.jpg";
import promotion from "../utils/apppromotion.jpg";
import brand from "../utils/brand.jpg";
import digital from "../utils/advertising.jpg";
import publicr from "../utils/public-relations.jpg";
import media from "../utils/media relations.jpeg";
import content from "../utils/content.jpeg";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "./footer";

export const Services = () => {
  const params = useParams();
  const navigate = useNavigate();
  console.log(params);
  const services = [
    {
      id: 1,
      title: "Strategic Planning",
      info:
        "We work closely with clients to launch new products and services. We do this by developing comprehensive marketing, advertising, and PR strategies that align with their marketing goals and objectives. Our solutions are curated to meet the needs and objectives of our clients. We will continually monitor and analyze your campaign performance, making real time adjustments to ensure maximum return on investment. With us, the promise is increased ad visibility, higher engagement and conversion rates and sprangling your brand across your industry.",
      sub: "We work closely with clients to launch new products and services. ",
      image: strategy,
    },
    {
      image: promotion,
      id: 2,
      title: "App Promotion",
      info:
        "We ignite your mobile application by exponentially increasing downloads and user acquisition up to 100x (10 times) more. We attain this on our various partner networksTrue to our core value of transparency and accountability, we provide periodic reports that detail progress and results on campaigns - clearly depicting intended results.Cinetico exists to help you achieve your set goals.",
      sub:
        "We ignite your mobile application by exponentially increasing downloads and user acquisition up to 100x (10 times) more ",
    },
    {
      image: brand,
      id: 3,
      title: "Brand Activation",
      info:
        "With a proven track record of successfully delivering to clients across a range of industries, we are passionate about helping businesses like yours bring their brand to life and create meaningful connections with their customers. Whether you're launching a new product or looking to reinvigorate your brand, our team delivers value every step of the way. From concept development to execution, we ensure that every detail is carefully considered to maximize impact and engagement.Our unique brand activation approach goes beyond promoting your product and services. We utilize a people-first approach to elicit clientele relationships that invoke brand loyalty and create a sense of community.Whether it's through experiential marketing, social media campaigns, influencer collaborations, or other innovative techniques, we you create a strong and lasting impression just for you",
      sub:
        " We are passionate about helping businesses like yours bring their brand to life and create meaningful connections with their customers.  ",
    },
    {
      image: digital,
      id: 4,
      title: "Digital Advertising",
      info:
        "We help our clients reach their target audience through uniquely curated digital advertising campaigns. Our team of experts collaborate with you to develop and execute a digital advertising plan that maximizes your return on investment.Our approach is pragmatic and programmatic. We give you hands-on experience using advanced technology. We do this by automatic digital ads across multiple platforms. For you, this means optimizing your budget while reaching the target audience at the right time, place and on the right device",
      sub:
        " We help our clients reach their target audience through uniquely curated digital advertising campaigns.   ",
    },
    {
      image: publicr,
      id: 5,
      title: "Public Relations",
      info:
        "We write your story in a compelling way to create and maintain a positive brand image with your niche audience. Your brand reputation and positive media coverage is why the Team at Cinetico rises at dawn.",
      sub:
        " We write your story in a compelling way to create and maintain a positive brand image with your niche audience.   ",
    },
    {
      image: media,
      id: 6,
      title: "Media Relations",
      info:
        "As you come on board, you ride on the horseback of our profound relationships with top-tier media outlets. You enjoyed increased visibility, credibility and world wide coverage.",
      sub:
        " You enjoyed increased visibility, credibility and world wide coverage",
    },
    {
      image: content,
      id: 7,
      title: "Content Marketing",
      info:
        "We position your brand as an industry thought leader using high quality and original content. Our Team of Expert Writers, Designers and Video Producers curate content that resonates with your audience, adds to your bottom line and drives conversions.",
      sub:
        " We position your brand as an industry thought leader using high quality and original content.",
    },
  ];
  const [pageService, setPageService] = useState(
    services.find((item) => item.id === parseInt(params.id))
  );
  const [others, setOthers] = useState(
    services.filter((item) => item.id !== parseInt(params.id))
  );
  console.log(pageService);

  useEffect(() => {
    window.scroll(0, 0);
    setPageService(services.find((item) => item.id === parseInt(params.id)));
    setOthers(services.filter((item) => item.id !== parseInt(params.id)));
  }, [params]);

  return (
    <div>
      <NavbarForServices></NavbarForServices>
      <div className="pt-10 px-2 md:px-5 pb-4 bg-slate-100 min-h-screen">
        <h5 className="text-center uppercase text-xl md:text-3xl text-green-700 ">
          {pageService.title}
        </h5>
        <div className=" flex justify-center ">
          <div className="h-[3px] w-[70px] mt-[-4px] bg-green-700 mb-3"></div>
        </div>
        <p className="mt-3 md:mt-5 text-base md:text-xl px-2 md:px-6  ">
          {pageService.info}
        </p>
        <div className="mt-11">
          <h5 className="text-center uppercase text-xl md:text-3xl text-green-700 ">
            Other Services
          </h5>
          <div className=" flex justify-center ">
            <div className="h-[3px] w-[70px] mt-[-4px] bg-green-700"></div>
          </div>

          <div className="mt-[20px]   grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-3 ">
            {others.map((item) => {
              return (
                <div className="shadow-xl  flex flex-col py-3 items-center justify-center text-center px-3 mt-5 md:mr-3">
                  <img
                    src={item.image}
                    alt=""
                    className="mb-4 h-[200px] object-cover object-center w-full "
                  />
                  <div className="px-3 flex flex-col  items-center justify-center">
                    <h3 className="font-bold">{item.title}</h3>
                    <p>{item.sub}</p>
                    <div
                      onClick={() => navigate(`/services/${item.id}`)}
                      className="mt-3 flex items-center hover:cursor-pointer  hover:scale-105 transition-all "
                    >
                      <h4 className="text-lg hover:text-green-700 text-green-700 mr-2 mt-[2px]">
                        Read More
                      </h4>
                      <i className="bi bi-arrow-down-right-circle-fill text-lg hover:text-green-700 text-green-700 mt-[-2px]"></i>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Services;
