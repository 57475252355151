import React, { useEffect, useState } from "react";
import { NavbarForServices } from "./navbarforservices";
import { NavbarForAdmin } from "./navbarforadmin";
import { message } from "./message";
import { Footer } from "./footer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

export const Admin = () => {
  const user = useSelector((state) => {
    return state.user.token;
  });
  const [messages, setmessages] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      setLoading(false);
      const data = await Axios.get("https://cineticoapi.onrender.com/messages");
      setmessages(data.data.data);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const deletemessage = async (id) => {
    setLoading(true);
    try {
      const data = await Axios.delete(
        `https://cineticoapi.onrender.com/deletemessage/${id}`
      );
      setmessages(messages.filter((item) => item._id !== id));
      // alert("Deleted Successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
    console.log(user);
    getData();
  }, []);
  console.log(messages);
  return (
    user && (
      <div>
        <NavbarForAdmin></NavbarForAdmin>
        {loading && (
          <div className="fixed top-0 h-[100vh] w-screen z-50 overflow-x-hidden bg-[#00000064] bg-cover flex justify-center items-center">
            <svg
              class="pl overflow-x-hidden"
              viewBox="0 0 200 200"
              width="200"
              height="200"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient id="pl-grad1" x1="1" y1="0.5" x2="0" y2="0.5">
                  <stop offset="0%" stop-color="hsl(313,90%,55%)" />
                  <stop offset="100%" stop-color="hsl(223,90%,55%)" />
                </linearGradient>
                <linearGradient id="pl-grad2" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stop-color="hsl(313,90%,55%)" />
                  <stop offset="100%" stop-color="hsl(223,90%,55%)" />
                </linearGradient>
              </defs>
              <circle
                class="pl__ring"
                cx="100"
                cy="100"
                r="82"
                fill="none"
                stroke="url(#pl-grad1)"
                stroke-width="36"
                stroke-dasharray="0 257 1 257"
                stroke-dashoffset="0.01"
                stroke-linecap="round"
                transform="rotate(-90,100,100)"
              />
              <line
                class="pl__ball"
                stroke="url(#pl-grad2)"
                x1="100"
                y1="18"
                x2="100.01"
                y2="182"
                stroke-width="36"
                stroke-dasharray="1 165"
                stroke-linecap="round"
              />
            </svg>
          </div>
        )}
        <div className="py-6 mb-[60px]">
          <div className="text-center text-blue-500 text-2xl fade-in-tl ">
            Messages
          </div>

          {messages.length > 0 ? (
            <div className="grid grid-cols-1 px-[40px]">
              {messages.map((item) => {
                return (
                  <div className="mt-3 border border-blue-400 rounded-xl p-3">
                    <p>
                      <span className="text-base text-blue-500">Name: </span>
                      {item.name}
                    </p>
                    <p>
                      <span className="text-base text-blue-500">Email: </span>
                      {item.email}
                    </p>
                    <p>
                      <span className="text-base text-blue-500">Message: </span>
                      {item.message}
                    </p>

                    <div className="flex mt-4 mb-2">
                      <button
                        onClick={() => {
                          deletemessage(item._id);
                        }}
                        className="rounded-lg py-1 px-2 shadow-xl bg-[red] text-white"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <h5 className="mt-5 ml-4 fade-in-tl ">No messages to show</h5>
            </div>
          )}
        </div>
        <div className="mt-6 fixed bottom-0">
          <Footer></Footer>
        </div>
      </div>
    )
  );
};

export default Admin;
